<template>
  <div class="addressDays">
    <!-- Loader -->
    <ul v-show="loading">
      <li v-for="n in 7" :key="n">
        <a href="javascript:void(0);">
          <span>
            <strong><q-skeleton type="rect" height="30px"/></strong>
            <q-skeleton
              type="text"
              width="80px"
              height="20px"
              class="q-mt-xs"
            />
          </span>
        </a>
      </li>
    </ul>
    <!-- Loader -->
    <ul v-show="!loading">
      <li
        @click="setActiveIndex(index)"
        v-for="(slot, index) in slots"
        :key="index"
        :class="{
          current: index == activeIndex,
        }"
      >
        <a href="javascript:void(0);">
          <span>
            <strong>{{ new Date(slot.date).getDate() }}</strong>
            {{ index == 0 ? 'Today' : slot.day.slice(0, 3) }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    slots: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'cb',
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    updateField: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setActiveIndex(index) {
      this.updateField(`${this.type}ActiveIndex`, index)
    },
  },
}
</script>
